import React from "react"
import NavbarFixed from "./NavbarFixed";
import Sidebar from "./Sidebar";
import Layout from "./Layout";
import {Link} from "gatsby";
import Img from "gatsby-image";
import ReactMarkdown from "react-markdown";

const SingleLanding = ({title, grigliaOggetti, metaTitle, metaDescription, footerDescription}) => {
    return (
        <>
            <NavbarFixed/>
            <Sidebar empty></Sidebar>
            <Layout nofooter oneModal title="Landings" title={metaTitle} description={metaDescription}>
                <div className="collection">
                    <div className="phantom-column-collection"></div>
                    <div className="pics-container">
                        <div className="collection-title get-bigger">
                            <h1>{title}</h1>
                            <div className="underline"></div>
                        </div>
                        {grigliaOggetti && grigliaOggetti.length > 0 ? (
                            <ul className="grid-pics">
                                {grigliaOggetti.map((oggetto, index) => (
                                    <li key={index} className="grid-pic">
                                        <Link
                                            to={`/collection/${oggetto.indirizzo}`}
                                            alt={oggetto.indirizzo}
                                        >
                                            <Img
                                                className="preview"
                                                backgroundColor="#b8d6d2"
                                                FadeIn={true}
                                                durationFadeIn={2000}
                                                fluid={oggetto.fotoAnteprima.fluid}
                                                alt={`${oggetto.designer} - ${oggetto.titolo} - ${oggetto.sottocategoria}`}
                                            />
                                            <div className="preview-info">
                                                <h2>
                                                    <div>{oggetto.designer}</div>
                                                    <p>{oggetto.titolo}</p>
                                                </h2>
                                            </div>
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <p>Nessun oggetto disponibile</p>
                        )}
                        <div className="descriptionFooterLanding">
                            <ReactMarkdown source={footerDescription}/>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default SingleLanding
